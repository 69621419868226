import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
class Loading extends React.Component {
	render() {
		return (
			<div className="d-flex flex-column justify-content-center align-items-center vh-100">
				<Spinner animation="border" className="text-secondary mb-2" />
			</div>
		)
	}
}
export default Loading