import React from 'react';
import Iframe from 'react-iframe';
import { Helmet } from 'react-helmet';

class Schedule extends React.Component {
	render() {
		const data = this.props.data;
		return (
			<div className="py-4 container">
				<Helmet>
					<meta name="fragment" content="!" />
					<title>{'Schedule a service | '+data.clientName}</title>
					<meta name="description" content={data.metaDescription} />
					<meta property="og:title" content={'Schedule a service | '+data.clientName} />
					<meta property="og:image" content={data.clientIcon} />
				</Helmet>
				<div className="py-3">
					<h1>Schedule Service</h1>
					<p>Complete the form below to see our availability in your area and immediately schedule a technician.</p>
					<p>In a few moments you will receive an email confirming your appointment has been sent into our system. Our office staff will contact you via email and phone to confirm when our tech can come out. If you have any questions or you have forgotten information you would like added to the service call please contact the office.</p>
					<p>Please be aware that any calls booked online after 4:00pm Friday or on a holiday will not be seen or confirmed for a tech until the next business day.</p>
				</div>
				<div className="py-3">
					<Iframe url={`https://booking.rossware.com/schedule/${data.clientID}`}
						width="100%"
						height="450px"
						id="myId"
						className="sdcoiFrame"
						display="initial"
						position="relative"
						frameBorder="0"/>
				</div>
			</div>
		);
	}
}
export default Schedule;