require('dotenv').config();

export default async function getSiteData(domain, useClientId){
    try{
        const api_url = process.env.REACT_APP_API_URL;
        const results = await fetch(`${api_url}/v3/publicTurnkeyData?${useClientId ? 'client_id' : 'domain'}=${encodeURIComponent(domain)}`);
        console.log(results);
        const objectData = await results.json();
        console.log(objectData);
        if (results.status > 199 && results.status < 400) {
            return objectData;
        }
    } catch(error) {
        console.log(`getSiteData failed with error: ${error}`);
    }
}