import React from 'react';
class Footer extends React.Component {
	render() {
		const data = this.props.data;
		return (
			<div className="py-5 bg-light">
				<div className="container grid-2-col">	
					<div>
						<h4 className="mb-4">Business hours</h4>
						<div className="text-dark mb-2">{data.contactHours1}</div>
						<div className="text-dark mb-4">{data.contactHours2}</div>
						{data.contactAddress1 && <h4 className="mb-4">Location</h4>}
						{data.contactAddress1 && <div className="text-dark mb-2">{data.contactAddress1}</div>}
						{data.contactAddress2 && <div className="text-dark mb-2">{data.contactAddress2}</div>}
						{data.contactAddress3 && <div className="text-dark mb-2">{data.contactAddress3}</div>}
					</div>
					<div>
						<h4 className="mb-4">Contact us</h4>
						<a className="text-dark mb-2 d-block" href={`tel:${data.contactPhone1}`}>{data.contactPhone1}</a>
						<a className="text-dark mb-2 d-block" href={`tel:${data.contactPhone2}`}>{data.contactPhone2}</a>
						<a className="text-dark mb-2 d-block" href={`mailto:${data.contactEmail1}`}>{data.contactEmail1}</a>
						<a className="text-dark mb-2 d-block" href={`mailto:${data.contactEmail2}`}>{data.contactEmail2}</a>
					</div>

				</div>
				<div className="pt-4 d-flex justify-content-center flex-wrap">
					<div className="w-100 text-center mt-4">
						<a href="https://rossware.com"target="_blank" rel="noopener noreferrer" className="text-black-50 d-block">Powered by Rossware</a>
						<a href="https://rossware.com/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-black-50">Privacy Policy</a>
					</div>
				</div>
			</div>
		);
	}
}
export default Footer;