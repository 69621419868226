import React from 'react'

export default function renderParagraph(text) {
  try {
    const array = text.split('\n');
    var i = 1;
    return array.map(item => {
      return <p key={i++}>{item}</p>;
    })
  } catch {
    return;
  }
  
}
