import React from 'react';
import Iframe from 'react-iframe';
import { Helmet } from 'react-helmet';

class TechTracking extends React.Component {
	render() {
		const data = this.props.data;
		return (
			<div className="py-4 container">
				<Helmet>
					<meta name="fragment" content="!" />
					<title>{'Track your technician | '+data.clientName}</title>
					<meta name="description" content={data.metaDescription} />
					<meta property="og:title" content={'Track your technician | '+data.clientName} />
					<meta property="og:image" content={data.clientIcon} />
				</Helmet>
				<div className="py-3">
					<h1>Technician Tracking</h1>
					<p>Please enter your information below to check your technician status.</p>
				</div>
				<div className="py-3">
					<Iframe url={`https://booking.rossware.com/track-technician/${data.clientID}`}
						width="100%"
						height="450px"
						id="myId"
						className="sdcoiFrame"
						display="initial"
						position="relative"
						frameBorder="0"/>
				</div>
			</div>
		);
	}
}
export default TechTracking;