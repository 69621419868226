import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navigation from './components/navigation';
import Home from './pages/home';
import Schedule from './pages/schedule';
import TechTracking from './pages/tech-tracking';
import JobStatus from './pages/job-status';
import NotFound from './pages/not-found';
import Footer from './components/footer';
import getSiteData from './actions/getSiteData';
import _ from 'lodash';
import Loading from './components/loader';
import Favicon from 'react-favicon';
import ScrollToTop from './helpers/ScrollToTop';
import { Helmet } from 'react-helmet';
require('dotenv').config();

class Root extends React.Component {
	state = {
		loading: true,
		data: [],
	};
	componentDidMount() {
		var baseUrl;
		var useClientId = false;
		try {
			baseUrl = window.location.hostname.split('.')[0];
			if (baseUrl === 'www') {
				baseUrl = window.location.hostname.split('.')[1];
			}
		} catch {
			console.log('No Split')
		}

		var param = window.location.search.substring(1);
		if (baseUrl === 'localhost' && param === '') {
			baseUrl = 'rosswareturnkey'; // switch this out to test with other client sites
		} else if (param !== '' && param.length < 5) {
			baseUrl = param;
			useClientId = true;
		}
		console.log('base is: ' + baseUrl);
		getSiteData(baseUrl, useClientId).then((data) => {
			const dataObject = data.data[0];
			const clientData = {
				clientID: _.get(dataObject, 'client_id'),
				clientName: _.get(dataObject, 'client_name'),
				clientColor: _.get(dataObject, 'client_color'),
				googleTag: _.get(dataObject, 'google_tag'),
				clientLogo: _.get(dataObject, 'client_logo'),
				clientIcon: _.get(dataObject, 'client_icon'),
				homeImage: _.get(dataObject, 'home_image'),
				showBrands: _.get(dataObject, 'show_brands'),
				showSchedule: _.get(dataObject, 'show_schedule'),
				showTracking: _.get(dataObject, 'show_tracking'),
				showStatus: _.get(dataObject, 'show_status'),
				aboutTitle1: _.get(dataObject, 'about_title_1'),
				aboutText1: _.get(dataObject, 'about_text_1'),
				aboutTitle2: _.get(dataObject, 'about_title_2'),
				aboutText2: _.get(dataObject, 'about_text_2'),
				brandsTitle1: _.get(dataObject, 'brands_title_1'),
				brandsText1: _.get(dataObject, 'brands_text_1'),
				brandsList1: _.get(dataObject, 'brands_list_1'),
				brandsTitle2: _.get(dataObject, 'brands_title_2'),
				brandsText2: _.get(dataObject, 'brands_text_2'),
				brandsList2: _.get(dataObject, 'brands_list_2'),
				contactPhone1: _.get(dataObject, 'contact_phone_1'),
				contactPhone2: _.get(dataObject, 'contact_phone_2'),
				contactEmail1: _.get(dataObject, 'contact_email_1'),
				contactEmail2: _.get(dataObject, 'contact_email_2'),
				contactAddress1: _.get(dataObject, 'contact_address_1'),
				contactAddress2: _.get(dataObject, 'contact_address_2'),
				contactAddress3: _.get(dataObject, 'contact_address_3'),
				contactHours1: _.get(dataObject, 'contact_hours_1'),
				contactHours2: _.get(dataObject, 'contact_hours_2'),
				serviceArea: _.get(dataObject, 'service_area'),
			}
			this.setState({
				loading: false,
				data: clientData,
			})

			document.title = clientData.clientName;
		})
	}
	render() {
		if (this.state.loading) {
			return (
				<Loading />
			)
		}
		const data = this.state.data;
		const metaDescription = data?.aboutText1?.substring(0, 160) || 'No meta description';
		return (
			<div id="home">
				<Helmet>
					<meta name="fragment" content="!" />
					<title>{data.clientName}</title>
					<meta name="description" content={metaDescription} />
					<meta property="og:title" content={data.clientName} />
					<meta property="og:image" content={data.clientIcon} />
					<script>
						{`(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
						(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
						m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
						})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

						ga('create', '${data.googleTag && data.googleTag}', 'auto');
						ga('send', 'pageview');`}
					</script>
				</Helmet>
				<Favicon url={data.clientIcon} />
				<Router>
					<ScrollToTop />
					<Navigation data={this.state.data} />
					<Switch>
						<Route exact path="/">
							<Home data={this.state.data} />
						</Route>
						{data.showSchedule === 1 && <Route path="/schedule">
							<Schedule data={this.state.data} />
						</Route>}
						{data.showTracking === 1 && <Route path="/tech-tracking">
							<TechTracking data={this.state.data} />
						</Route>}
						{data.showStatus === 1 && <Route path="/job-status">
							<JobStatus data={this.state.data} />
						</Route>}
						<Route path="/*">
							<NotFound />
						</Route>
					</Switch>
					<Footer data={this.state.data} />
				</Router>
			</div>

		);
	}
}

export default Root;
