import React from 'react';
import { Link } from 'react-router-dom';

class NotFound extends React.Component {
	render() {
		return (
			<div className="py-5 d-flex flex-column justify-content-center align-items-center">
				<div className="py-5 text-center">
					<h1 className="display-1">404</h1>
					<h1>Whoops! There's nothing here.</h1>
					<p>The page you're looking for doesn't seem to exist. <Link to="/">Click here</Link> to return home.</p>
				</div>
			</div>
		);
	}
}
export default NotFound;