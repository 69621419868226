import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { isThereServiceArea } from '../helpers/functions';

class Navigation extends React.Component {

	render() {
		const data = this.props.data;
		return (
			<div className="nav-wrapper">
				<div className="container-fluid py-3 nav-grid">
					<HashLink className="nav-brand d-flex align-items-center" to="/#home">
						{data.clientLogo ? <img src={data.clientLogo} alt="Company Logo" className="client-logo" /> : <h1 className="client-logo-text" style={{ color: data.clientColor }}>{data.clientName}</h1>}
					</HashLink>
					<div className="nav-middle">
						<HashLink className="tk-nav-link" to="/#home">Home</HashLink>
						<HashLink className="tk-nav-link" to="/#about">About</HashLink>
						{data.showBrands === 1 && <HashLink className="tk-nav-link" to="/#brands">Brands</HashLink>}
						{isThereServiceArea(data.serviceArea) && <HashLink className="tk-nav-link" to="/#servicearea">Service area</HashLink>}
						{data.showTracking === 1 && <Link className="tk-nav-link" to="/tech-tracking">Technician Tracking</Link>}
						{data.showStatus === 1 && <Link className="tk-nav-link" to="/job-status">Job Status</Link>}
					</div>
					<div className="d-flex justify-content-center align-items-center">
						<span><a className="text-dark mr-3" href={`tel:${data.contactPhone1}`}>{data.contactPhone1}</a></span>
						<Link className="btn text-white"  style={{backgroundColor: data.clientColor }} to="/schedule">Book us online</Link>
					</div>
				</div>

			</div>

		);
	}
}
export default withRouter(Navigation);
