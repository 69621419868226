import React from 'react';
import renderParagraph from '../helpers/DOMWorkers';
import { Link } from 'react-router-dom';
import { isThereServiceArea } from '../helpers/functions';

class Home extends React.Component {
	render() {
		const data = this.props.data;
		const brandsList1 = data.brandsList1 ? data.brandsList1.split(",") : false;
		const brandsList2 = data.brandsList2 ? data.brandsList2.split(",") : false;

		return (
			<div className="pb-5">
				<div className="position-relative text-white d-flex align-items-center" style={{ height: data.homeImage ? '440px' : '240px', backgroundColor: data.clientColor }}>
					{data.homeImage ? <img src={data.homeImage} className="home-hero-img" alt="Home hero"></img> : <div className="white-overlay" />}
				</div>
				<div className="container pt-5 grid-home">
					<div className="border-children">
						<div className="scroll-margin-top" id="about">
							{data.aboutText1 && <div>
								<h1 className="h4">{data.aboutTitle1 ? data.aboutTitle1 : 'About us'}</h1>
								<div>{renderParagraph(data.aboutText1)}</div>
							</div>
							}
							{data.aboutText2 &&
								<div>
									<h1 className="h4 mt-5">{data.aboutTitle2}</h1>
									<div>{renderParagraph(data.aboutText2)}</div>
								</div>
							}
							{data.aboutText3 &&
								<div>
									<h1 className="h4 mt-5">{data.aboutTitle3}</h1>
									<div>{renderParagraph(data.aboutText3)}</div>
								</div>
							}
						</div>
						{isThereServiceArea(data.serviceArea) &&
							<div className="scroll-margin-top" id="servicearea">
								<h1 className="h4">Service area</h1>
								<div>{renderParagraph(data.serviceArea)}</div>
							</div>
						}
						<div className="scroll-margin-top" id="brands">
							<h1 className="h4">{data.brandsTitle1}</h1>
							<p>{data.brandsText1}</p>
							<div className="grid-4-col">{
								brandsList1 && brandsList1.map(brand => {
									return (
										<p key={brand} className="mb-1">
											{brand}
										</p>
									)
								})
							}</div>
						</div>
						<div>
							<h1 className="h4">{data.brandsTitle2}</h1>
							<p>{data.brandsText2}</p>
							<div className="grid-4-col">{
								brandsList2 && brandsList2.map(model => {
									return (
										<p key={model} className="mb-1">
											{model}
										</p>
									)
								})
							}</div>
						</div>
					</div>
					<div className="sidebar-wrapper">
						<div className="sidebar text-center bg-white py-5 px-4 shadow rounded">
							<h4 className="text-center mb-4">Book us online</h4>
							<p>Click the button below to quickly and easily schedule a service with us.</p>
							<Link className="btn text-white" style={{backgroundColor: data.clientColor }} to="/schedule">Schedule a service now</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default Home;
